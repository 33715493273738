<template>
  <div>
    <div class="content">
      <div class="SearchBar">
        <el-button  @click="closeadd" size="mini" class="backbtn">返回</el-button>
        <el-form label-position="right" label-width="110px">
          <el-row class="row">
            <el-col :span="8">
              <el-form-item label="模板名称:" prop="result">
                {{ form.tplName }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="用户分类:" prop="result">
                {{ form.userTypeName }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="启用状态:" prop="result">
                {{ form.generalStatus==0 ? '禁用' : '启用' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8">
              <el-form-item label="采集项:" prop="result">
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-table
              ref="tableData"
              :data="form.aaumItems"
              style="margin:0px 0px 20px 65px"
              border
              :stripe="true"
            >
            <el-table-column
          type="index"
          label="序号"
          align="center"
          width="50"
          >
          </el-table-column>
              <el-table-column prop="itemName" label="采集项名称" align="center" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="inputType" label="录入类型" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{ starts(row.inputType) }}
                </template>
              </el-table-column>
              <el-table-column prop="requiredFields" label="必填项" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{row.requiredFields == 1 ? '是' : '否'}}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { templateDetails } from "@/RequestPort/dict/cycle";
export default {
  data() {
    return {
      form:{},
      tableData: [
        {name:'大清洗'},
        {name:'小清洁'},
      ], //  表格数据
    }
  },
  mounted() {
    this.particulars(this.$route.query.id)
  },
  methods: {
    starts(val){
      if(val == 1) return '文本框'
      if(val == 2) return '单选'
      if(val == 3) return '多选'
      if(val == 4) return '上传照片'
      if(val == 5) return '数字框'
    },
    //返回
    closeadd() {
      // this.$router.go(-1)
      this.$router.push({ path: '/home/checkDict', query: { first: 1, screcd: 2 } })
    },
    particulars(id) {
      templateDetails({id}).then(res =>{
        this.form = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #f3f4f8;;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 150px);
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.backbtn{
  margin:30px 0px 40px 30px;
  width: 82px;
  height: 36px;
}
.row{
  margin-left: 10px;
}
.row2{
  margin-left: 10px;
  margin-top: 10px;
}
.titleDiv{
  margin: 26px 0px 0px 30px;
}
</style>
